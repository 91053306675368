<template>
  <div class="wrap">
    <div class="header" ref="headerRef">
      <headerTop></headerTop>
    </div>
    <div class="topBox">
      <p class="title">「 全析解析识别技术 」</p>
      <span class="txt"
        >一种从表单类影像中全方位解析内容的技术解决方案。全析解析的含义不仅仅包含识别单证本身的文字，更加深拓展了对其内容的理解能力和延伸使用判断。能够从所使用行业的角度，对领域内人员提供专业辅助解析和理解作用的技术方案。</span
      >
    </div>
    <ul class="fourBox">
      <li>
        <img src="../assets/img/analysis/chineseName.png" alt="" />
        <span class="name">中文名</span>
        <span class="explain">全析解析</span>
      </li>
      <i class="line"></i>
      <li>
        <img src="../assets/img/analysis/englishName.png" alt="" />
        <span class="name">英文名</span>
        <span class="explain">Meta-Insight</span>
      </li>
      <i class="line"></i>
      <li>
        <img src="../assets/img/analysis/category.png" alt="" />
        <span class="name">类别</span>
        <span class="explain">AI Technology</span>
      </li>
      <i class="line"></i>
      <li>
        <img src="../assets/img/analysis/principle.png" alt="" />
        <span class="name">原理</span>
        <span class="explain">深度学习，元学习，多模态学习</span>
      </li>
    </ul>
    <div class="pageBox page01">
      <div class="titBox">
        <span class="number">01</span>
        <div class="txt">
          <span class="english">HISTORICAL EVOLUTION</span>
          <span class="chinese">历史沿革</span>
          <i class="line"></i>
        </div>
      </div>
      <div class="content">
        <div class="engTit">HISTORICAL EVOLUTION</div>
        <div class="box">
          <img class="img" src="../assets/img/analysis/ban_01.png" alt="" />
          <p class="p">
            最初提出是在医疗、银行、保险公司的RPA业务实现过程中，在具体业务场景的影像材料识别环节，传统的OCR技术不能满足表单类业务的数据格式化需求，同时，传统的语义理解无法作用于行业业务知识，从而难以有效完成从识别到业务自动化驱动的AIRPA的要求。如传统光学字符识别只能实现基础的文字识别，而不能很好的提取关键要素；因过分依赖固定版式，而无法适应实际业务中广泛的表单样式，导致在数据提取环节无法达到理想的准确度，难以达到降低重人力的工作的目标。业务专家仍然需要大量参与到基础知识的判断中，为字段指定业务含义，再进行后续的业务流程；受传统数据库设计思想的影响，格式化的数据实际只被提取信息中很少部分，造成数据解析过程中的大量信息丢失，进而限制了使用场景。
            <br />
            中科睿见算法团队首先在医疗场景下，提出并实现了“医疗资料全析解析识别”技术并在其智能保险云平台产品系列中进行广泛应用。用于医疗解读、保险业务自动化、银行业务自动化、企业级影像搜索引擎等多种业务场景。实现了表单影像内容的全部抽取和要素的结构化，并在要素结构化的基础上实现了业务含义解读及标准化处理。算法中融入了错误辨析机制，在数据提取、业务归一、错误辨析三个维度提升了技术方案的准确性、可靠性和效率。
            <br />
            后在持续深入保险核心业务的基础上，拓展到核保环节以及大健康领域。融合加入了版面分析、知识图谱、知识库、对话机器人、元学习、多模态学习等多种前沿的人工智能技术，打磨形成了一整套专业业务支持能力。
          </p>
          <i class="yelLine"></i>
        </div>
      </div>
    </div>
    <div class="pageBox page02">
      <div class="titBox">
        <span class="number">02</span>
        <div class="txt">
          <span class="english">BUSINESS SCENARIO</span>
          <span class="chinese">业务场景</span>
          <i class="line"></i>
        </div>
      </div>
      <div class="content">
        <div class="engTit">BUSINESS SCENARIO</div>
        <div class="box">
          <img class="img" src="../assets/img/analysis/ban_02.png" alt="" />
          <p class="p">
            医疗资料解读是保险公司健康险业务的核心业务场景，主要通过对投保客户提交的多种医疗资料进行解读，最终生成业务场景使用的结论性数据或记录，是保险核心业务的基本处理单元。业务场景中，档案由多种医疗资料影像组成，可能包含票据、处方、化验单、影像报告、出/入院记录、病历、体检报告、费用清单、理赔单、分割单、各类证件、审批表、法律证明等几十种类别。作业场景中，需要对以上资料完成基础数据提取、业务要素提取、业务要素含义解读以及业务要素结论推理。以上过程，传统方式全部由具有医学背景的业务人员完成，或辅助以简单的OCR技术完成对少量目标数据的提取，但是无法自动驱动整个工作流。全析解析识别技术可通过对数据的解析、理解、推理完成整个工作流的智能驱动。
          </p>
          <i class="yelLine"></i>
        </div>
      </div>
    </div>
    <div class="pageBox page03">
      <div class="titBox">
        <span class="number">03</span>
        <div class="txt">
          <span class="english">TECHNICAL PROCESS</span>
          <span class="chinese">技术过程</span>
          <i class="line"></i>
        </div>
      </div>
      <div class="content">
        <div class="engTit">TECHNICAL PROCESS</div>
        <div class="box">
          <img class="img" src="../assets/img/analysis/ban_03.png" alt="" />
          <p class="p">
            <span class="titl">主要分为以下几个过程：</span>
            <span class="item"
              ><span class="circularBox"><span class="circular"></span></span><span class="blue">信息抽取：</span>
              包含影像分类、照片质量控制、模糊文字清晰化、印章提取/识别、文字定位、文字识别、版面分析、表格检测/解析等。该环节的难点在于正确识别文字的基础上，对业务要素的提取和表格的结构化。本方案通过构建上述步骤的算法模块，极大提升了实现效能。
            </span>
            <span class="item"
              ><span class="circularBox"><span class="circular"></span></span><span class="blue">知识抽取：</span>
              包含构建大规模专业标准化知识库，和构建专业知识图谱，形成计算机可直接使用的知识。难点在于专业术语的归一化和非结构化文本的结构化和要素关联关系的建立。本方案通过通过定期整理各区域行业数据库实现药品、项目、耗材的及时更新，并构建了语义分析模型，将相似表述的专业术语归一化；读取临床治疗指南自动构建专业的知识图谱，并经过专家人工审核，实现知识图谱各个相关元素的关联和系统化。
            </span>
            <span class="item"
              ><span class="circularBox"><span class="circular"></span></span><span class="blue">信息分析/理解：</span>
              结合抽取的信息、专业的知识库和知识图谱，给出可解释的结论。由于传统方式完全由人根据自己的经验出具结论，但其中奉行的因果关系，并没有明确的描述，也没有公认的标准，存在很多弹性地带。当系统自动出具结论后，即便结论与专家的结论一致，也需要阐述结论的推论过程。难点在于如何得出合理的结论，以及将推论过程进行清晰化的描述。本方案通过将业务数据结构化、规则化，将核验数据与知识图谱、知识库联动起来，完成整个推理过程。
            </span>
            <span class="item"
              ><span class="circularBox"><span class="circular"></span></span><span class="blue">影像全局信息搜索：</span>
              从海量影像中快速按影像类别、地区、时间及关键字搜索到对应的内容。难点在于传统搜索都是基于文本关键字或自然场景影像，没有基于单证影像的内容搜索技术。本方案创造性的实现了从影像中搜索文字内容的能力，目前市场上并无类似产品。
            </span>
          </p>
          <i class="yelLine"></i>
        </div>
      </div>
    </div>
    <div class="pageBox page04">
      <div class="titBox">
        <span class="number">04</span>
        <div class="txt">
          <span class="english">TECHNICAL REALIZATION</span>
          <span class="chinese">技术实现</span>
          <i class="line"></i>
        </div>
      </div>
      <div class="content">
        <div class="engTit">TECHNICAL REALIZATION</div>
        <div class="box">
          <img class="img" src="../assets/img/analysis/ban_04.png" alt="" />
          <p class="p">
            利用深度学习算法对影像类文件进行图像处理、目标定位、文本检测、文字识别、印章检测识别、表格检测识别、版面分析、图像分类、语义联想、关键信息结构化，将提取的有效信息进行中文分词、语义标准化、倒排索引链表，最终以结构化数据形式存入关系型数据库中。<br />
            该项技术中心嵌入了行业知识图谱、行业标准词库等、并结合不同的业务场景和逻辑，实现场景化协同处理和分析的能力。<br />
            该项技术已申请知识产权的智能云分析版本，在实现上主要由图像信息抽取模块、数据分析模块、查询模块等组成。模块之间的连接关系如图所示：
            <img class="ban04_img" src="../assets/img/analysis/ban04_img.png" alt="" />
            <span class="item"
              ><span class="circularBox"><span class="circular"></span></span
              ><span class="blue">图像信息抽取模块：</span>
              包括图像矫正、降噪处理、目标定位、文字检测、文字识别、印章检测/识别、表格检测/识别、版面分析、图像分类、关键信息结构化。在最终进入的业务系统进行数据处理之前，通过逻辑校验模块，包含反向推理的过程，将关键信息进行循环校正和指引，确保最终数据的正确性。<br />
              图像矫正是对影像的预处理操作，是影像信息抽取的基础，图像矫正的效果直接影响各个深度学习模型的准确率。<br />
              文本检测识别是由文本检测和文本识别两部分组成，是将影像文档中的文字信息进行提取和识别，是信息抽取的关键步骤，文本检测和识别的准确率直接影响信息抽取的效果。<br />
              印章检测识别是用于提取影像中的印章信息，用于快速检测影像中有无印章，定位印章位置并可识别印章内容。<br />
              表格检测识别是用于提取影像中的表格信息，用于快速检测影像中有无表格，对于完整框线的常规简单表格，结构化输出表头、表尾及每个单元格的文字内容。<br />
              版面分析是将整张影像文档按照文档版面布局分割为不同的版面区域的过程。将版面分析和文本检测结果进行融合，可更好得到文档页面布局的位置和内容间的逻辑关系，从而有效提升影像文档内容的结构化提取效率和准确率。<br />
              图像分类是根据客户预先定义的影像文档类型，对影像文档进行分类。图像分类是聚合了文本检测识别、印章检测识别、表格检测识别、版面分析的结果之后，对影像文档进行分类。<br />
              关键信息结构化是根据客户预先定义的影像文档类型，针对每一类影像文档，定制化的提取文档中的关键信息。 逻辑校验模块将已结构化的关键信息进行循环校验，反向推理，进一步确保信息的可靠性。
            </span>
            <span class="item"
              ><span class="circularBox"
                ><span class="circularBox"><span class="circular"></span></span></span
              ><span class="blue">数据库模块：</span>
              构建了搜索引擎使用的数据库模块，包括分词处理、倒排索引、结构化数据存储，还调用了知识图谱和专业知识库实现逻辑处理。该模块支持分布式部署和搜索，可近似线性扩展。
            </span>
            <span class="item"
              ><span class="circularBox"><span class="circular"></span></span><span class="blue">查询模块：</span>
              为了方便客户搜索，查询模块增加了更人性化的功能，包括高亮显示搜索关键字、显示搜索耗时、高级搜索设置、显示搜索条件、显示结构化数据。其中高级搜索设置中可以设置影像类别、影像时间等信息。显示结构化数据是将影像信息抽取的结构化数据展示给用户，方便用于直接获取到结构化数据。
            </span>
          </p>
          <i class="yelLine"></i>
        </div>
      </div>
    </div>
    <div class="pageBox page05">
      <div class="titBox">
        <span class="number">05</span>
        <div class="txt">
          <span class="english">APPLICATION CASE</span>
          <span class="chinese">应用案例</span>
          <i class="line"></i>
        </div>
      </div>
      <div class="content">
        <div class="engTit">APPLICATION CASE</div>
        <div class="box">
          <img class="img" src="../assets/img/analysis/ban_05.png" alt="" />
          <p class="p">
            该方案已在中科睿见自研的SaaS保险智能云平台、天玑智能保险平台等场景中生产使用，为中国人寿、北京人寿、中意人寿、中华联合保险、爱心人寿、盐城市医保中心等多家保险公司和政企单位提供服务。
          </p>
          <i class="yelLine"></i>
        </div>
      </div>
    </div>
    <div class="pageBox page06">
      <div class="titBox">
        <span class="number">06</span>
        <div class="txt">
          <span class="english">EXPAND READING</span>
          <span class="chinese">拓展阅读</span>
          <i class="line"></i>
        </div>
      </div>
      <div class="content">
        <div class="engTit">EXPAND READING</div>
        <div class="box">
          <img class="img" src="../assets/img/analysis/ban_06.png" alt="" />
          <p class="p">中科睿见官网：<a class="href" href="https://www.smart-insight.com.cn">https://www.smart-insight.com.cn</a></p>
          <i class="yelLine"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerTop from '@/components/header.vue'
export default {
  name: 'Analysis',
  components: {
    headerTop
  },
  data() {
    return {}
  },
  created() {
    window.scroll(0, 0)
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  overflow-x: hidden;
  overflow: hidden;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  z-index: 999;
}
.topBox {
  // position: relative;
  margin-top: 60px;
  width: 100%;
  height: 660px;
  background: url('../assets/img/analysis/top_bg.png') no-repeat 100% 100%;
  overflow: hidden;
  .title {
    margin-top: 110px;
    margin-left: 50%;
    // width: 713px;
    transform: translateX(-50%);
    height: 62px;
    font-size: 44px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 100;
    color: #ffffff;
    line-height: 62px;
    letter-spacing: 20px;
  }
  .txt {
    display: inline-block;
    margin-top: 70px;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1000px;
    // height: 138px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
  }
}
.fourBox {
  position: relative;
  top: -104px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 1258px;
  height: 305px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 1px #cadcfe;
  li {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      margin-top: 60px;
      width: 72px;
      height: 72px;
    }
    .name {
      margin-top: 40px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #626273;
      line-height: 28px;
    }
    .explain {
      margin-top: 20px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #8f8f9b;
      line-height: 25px;
    }
  }
  .line {
    display: inline-block;
    margin-top: 72px;
    width: 1px;
    height: 152px;
    background-color: #ededef;
  }
}
.pageBox {
  padding-left: 69px;
  margin-bottom: 45px;
  // background-color: pink;
  .titBox {
    position: relative;
    .number {
      // width: 121px;
      height: 168px;
      font-size: 120px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #f0f7ff;
      line-height: 168px;
    }
    .txt {
      position: absolute;
      top: 73px;
      left: 32px;
      .english {
        display: block;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #74a3fa;
        line-height: 25px;
      }
      .chinese {
        display: inline-block;
        margin-top: 1px;
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #74a3fa;
        line-height: 37px;
      }
      .line {
        position: absolute;
        top: 42px;
        left: 124px;
        display: inline-block;
        width: 130px;
        height: 4px;
        background: #fdfb3b;
      }
    }
  }
  .content {
    position: relative;
    margin-top: -20px;
    margin-right: 80px;
    padding-left: 10px;
    // background-color: skyblue;
    text-align: right;
    // overflow: hidden;
    .engTit {
      // width: 1219px;
      height: 132px;
      font-size: 80px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #f0f7ff;
      line-height: 112px;
      text-align: right;
    }
    .box {
      position: relative;
      display: inline-block;
      // position: absolute;
      margin-top: -67px;
      // right: 12px;
      width: 1088px;
      // height: 652px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 1px #cadcfe;
      // overflow: hidden;
      .img {
        position: absolute;
        top: 82px;
        left: -180px;
        width: 474px;
        height: 316px;
      }
      .p {
        margin-top: 51px;
        margin-left: 362px;
        width: 659px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #010c29;
        line-height: 36px;
        text-align: left;
      }
      .yelLine {
        position: absolute;
        bottom: 0;
        right: -12px;
        width: 12px;
        height: 316px;
        background: #fdfb3b;
      }
    }
  }
}
.page01 {
  margin-top: -23px;
  .content {
    .box {
      .p {
        margin-bottom: 50px;
      }
      .yelLine {
        height: 316px;
      }
    }
  }
}
.page02 {
  .content {
    .box {
      .p {
        margin-bottom: 148px;
      }
      .yelLine {
        height: 230px;
      }
    }
  }
}
.page03,
.page04 {
  .content {
    .box {
      .p {
        margin-bottom: 55px;
        .titl {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #010c29;
          line-height: 22px;
        }
        .item {
          position: relative;
          display: block;
          padding-left: 12px;
          margin-top: 11px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 36px;
          .circularBox {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            height: 36px;
            width: 5px;
            overflow: hidden;
            // background-color: pink;
            .circular {
              // position: absolute;
              // top: 50%;
              // left: -12px;
              margin-bottom: 2px;
              display: inline-block;
              width: 5px;
              height: 5px;
              background: #74a3fa;
              border-radius: 50%;
            }
          }

          .blue {
            color: #74a3fa;
          }
        }
        .ban04_img {
          margin-top: 15px;
          margin-bottom: 73px;
          width: 100%;
        }
      }
      .yelLine {
        height: 368px;
      }
    }
  }
}
.page04 {
  .yelLine {
    height: 887px !important;
  }
}
.page05,
.page06 {
  .content {
    .box {
      .p {
        margin-top: 214px;
        margin-bottom: 214px;
        a {
          color: #252525;
          text-decoration: none;
        }
        a:visited {
          text-decoration: none;
        }
        a:hover {
          color: #4671FF;
          text-decoration: underline;
        }
        a:active {
          color: #4671FF;
        }
      }
      .yelLine {
        height: 230px;
      }
    }
  }
}
.page06 {
  margin-bottom: 139px;
}
</style>
